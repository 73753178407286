
.image-body {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.image-content {
    width: 80px;
    height: 80px;
    border-radius: 4px;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
    }
}

